.table {
    border-collapse: separate;
    border-spacing: 0 10px; /* Horizontal and vertical spacing */
}

table,
tr,
th,
td {
    border: none !important;
}


th {
    background-color: transparent !important;
    color: white !important;
}



.table-bordered th,
.table-bordered td {
    text-align: left;
    vertical-align: middle;
}

.table-bordered img {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

tr td:first-child {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
}

tr td:last-child {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

#table-body tbody {
    margin-top: 10px !important;
    background-color: red;
}


@media (max-width: 768px){
    .th-text{
        font-size: 0.8em;
    }
    .table{
        position: relative;
        right: 15px;
        
    }

}