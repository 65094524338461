nav {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    padding: 20px 80px;
    margin-top: 40px;
}

#latest-navbar-lists {
    display: flex;
    align-items: center;
    justify-content: center;
}

#latest-navbar-lists li {
    list-style: none;
    padding: 0px 20px;
    position: relative;
}

#latest-navbar-lists li a {
    text-decoration: none;
    font-size: 1.3rem;
    font-weight: 600;
    color: #7642ca;
    transition: 0.3s ease-in-out;
}

#latest-navbar-lists li a:hover,
#latest-navbar-lists li a.active-bar {
    color: white;
}

.btn-downlooad a{
    color:white !important
}

#latest-navbar-lists li a:hover::after,
#latest-navbar-lists li a.active-bar::after {
    content: '';
    width: 30px;
    height: 2px;
    background-color: #653aab;
    position: absolute;
    bottom: -4px;
    left: 20px;
}

#mob-nav {
    display: none;
}

#mob-nav i {
    color: white;
    align-items: center;
}

.drop-down-info {
    position: absolute;
    height: 150px;
    width: 250px;
    background-color: #653aab;
    border-radius: 25px;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    align-items: flex-start;
    top: 110px;
    right: 12%;
    z-index: 9999;
}

@media screen and (max-width:769px) {
    nav {
        background-color: #653aab;
        position: fixed;
        padding: 0px;
        top: -40px;
        width: 100%;
        z-index: 99999;
        display: flex;
        justify-content: space-between;
        padding: 15px
    }

    #latest-navbar-lists {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        position: fixed;
        top: 0px;
        right: -300px;
        width: 60%;
        height: 100vh;
        background-color: #5523a5;
        z-index: 2;
        padding: 40px 0 0 10px;
    }

    #latest-navbar-lists.active-nav {
        right: 0px;
    }

    #latest-navbar-lists li {
        margin-bottom: 25px;
    }

    #mob-nav {
        display: block;
    }

    #mob-nav i {
        color: white;
        font-size: 24px;
        cursor: pointer;
        position: relative;
        z-index: 9999;
    }

    .img-main-lgoo {
        height: 25px;
    }
    .drop-down-info {
        position: relative;
        height: 150px;
        width:200px;
        background-color: #653aab;
        border-radius: 25px;
        display: flex;
        justify-content: space-evenly;
        flex-direction: column;
        align-items: flex-start;
        top: 0;
        right: 0;
        z-index: 9999;
        margin-bottom: 20px;
    }

    .btn-downlooad{
        padding: 5px 50px !important;
        border-radius: 10px !important;
        
    }
}