.drop-down-points {
    width: 100%;
    border-radius: 1%;
    user-select: none;
    cursor: pointer;

}

.top-bar {
    height: 80px;
    width: 100%;
    background-color:rgb(71, 31, 136) ;
    display: flex;
    justify-content: space-between;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    user-select: none;
    cursor: pointer;
}



.top-sub-bar {
    height: 80px;
    width: 100%;
    background-color: rgb(107, 72, 163);
    display: flex;
    justify-content: space-between;
    padding-left: 50px;
    padding-right: 50px;
    align-items: center;
    border-bottom: 1px solid rgb(101, 58, 171);
}

.points-notice{
    background-color: #88549e ;
    color: white;
    text-align: center;
    border: 2px white solid;
    border-radius: 6px;
    padding: 10px;
    margin-bottom: 10px;
    vertical-align: middle !important;
}

.points-notice li{
line-height:  30px;
font: bold;
}

@media screen and (max-width:769px){
    .points-sys{
        margin-top: 80px !important;
    }
}