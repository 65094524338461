#profile-page-style .login-input {
    width: 90%;

    /* Default width */
    /* other styles */
}


.full-height {
    min-height: 100vh;
    /* 100% of the viewport height */
    position: relative
}

.header-profile {
    height: 120px;
    width: 120px;
    border: 1px solid white;
    border-radius: 100%;
    position: absolute;
    top: -60px;
    left: 40px;
}

.profile-icon-box {
    height: 45px;
    width: 45px;
    border: 1px solid #8763ca;
    background: white;
    border-radius: 100%;
    position: absolute;
    right: -8px;
    bottom: -3px;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
}

#profile-area .login-half-input {
    height: 50px;
    margin-left: 10px;
    margin-top: 20px;
}

#profile-area .login-full-input {
    height: 50px;
    width: 100%;
    margin-left: 10px;
    margin-top: 20px;

}

#profile-area .heading-bank-details {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 30px;
}

#profile-area .profile-btn-area button {
    margin: 10px;
    border-radius: 10px;
}

.modal-custom-enter {
    opacity: 0;
    transform: translateY(20%);
}

.modal-custom-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: opacity 900ms, transform 900ms;
}

.modal-custom-exit {
    opacity: 1;
}

.modal-custom-exit-active {
    opacity: 0;
    transform: translateY(20%);
    transition: opacity 900ms, transform 900ms;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}



.tab-content .input-modal-fields {
    position: relative;
    margin-top: 15px;
}

.tab-content .input-modal-fields::before,
.tab-content .input-modal-fields::after {
    content: '';
    position: absolute;
    pointer-events: none;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 30px;
    line-height: 30px;
}

.tab-content .input-modal-fields::before {
    content: 'Enter Amount';
    left: 15px;
    color: #a8a8a8;
}

.tab-content .input-modal-fields::after {
    content: 'PKR.';
    right: 10px;
    color: #a8a8a8;
}

.tab-content .input-modal-fields.has-content::before,
.tab-content .input-modal-fields.has-content::after {
    display: none;
}


/* ----------------------------------------------------- */


.withdraw-modal-body .tab-content .input-modal-fields {
    position: relative;
    margin-top: 20px;
}

.withdraw-modal-body .tab-content .input-modal-fields::before,
.withdraw-modal-body .tab-content .input-modal-fields::after {
    content: '';
    position: absolute;
    pointer-events: none;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 30px;
    line-height: 30px;
}

.withdraw-modal-body .tab-content .input-modal-fields::before {
    content: 'Enter Amount';
    left: 25px;
    color: #a8a8a8;
}

.withdraw-modal-body .tab-content .input-modal-fields::after {
    content: 'PKR.';
    right: 25px;
    color: #a8a8a8;
}

.withdraw-modal-body .tab-content .input-modal-fields.has-content::before,
.withdraw-modal-body .tab-content .input-modal-fields.has-content::after {
    display: none;
}



@media only screen and (max-width: 600px) {
    body {
        height: 100vmax;
    }

    .login-input {
        width: 95%;
    }

    .profile-icon-box {
        height: 35px;
        width: 35px;
        /* Adjust other properties as needed */
    }

    .profile-btn-area button {
        padding: 10px 15px;
        font-size: 14px;
    }
}