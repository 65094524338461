body {
  margin: 0;
  padding: 0;

  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  
}


