.tab-menu {
    /* background-color: red; */
    display: flex;
    border: 1px solid #ac86c4;
    border-radius: 20px;
    background-color: white;
}

.tab-menu button {
    border: none;
    outline: none;
    width: 100%;
    padding: 0px;
    background: none;
    border-left: red;
    font-weight: bold;
    color: #89559f;
}

.tab-menu button {
    border: none;
    outline: none;
    width: 100%;
    padding: 20px;
}

.tab-menu button:first-child {
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    border-right: 1px solid #88549e;
}

.tab-menu button:last-child {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-left: 1px solid #88549e;
}


@media (max-width: 768px) {
    .tab-menu button {
        border: none;
        outline: none;
        width: 100%;
        padding: 0px;
        font-size: 1em;
    }
}


.active-btn {
    background: #88549e !important;
    color: white !important;
    font-weight: bold;
}

.card {
    cursor: pointer;
}

.modal {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease;

}

.modal.show {
    opacity: 1;
    transform: translateY(0);
}


.contest-modal-btn button {
    width: 90%;
    border: none;
    padding: 15px 0px;
    background: linear-gradient(to right, #946dda, #573497);
}


.btn-outline-primary:hover {
    background-color: #653aab;
    border-color: #653aab;
    color: white;
    font-weight: bold;
    font-size: 15px;
}

.btn-outline-primary {
    background-color: white;
    width: 20%;
    height: 40px;
    border-radius:15px;
    color: #653aab;
    border-color: #653aab;
    font-weight: bold;
    font-size: 15px;
    /* float: right; */
}