@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

:root {
  --primary-color: rgba(151, 112, 220, 255);
  /* Blue color */
  --secondary-color: rgba(87, 51, 150, 255);
  /* Red color */
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
  list-style: none;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: #F2F2F2;
  border-radius: 3px;
}

::-webkit-scrollbar-thumb {
  background-color: var(--primary-color);
  border-radius: 3px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--secondary-color);
}

body {
  background: linear-gradient(to bottom, var(--primary-color), var(--secondary-color));
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif;
}

input {
  color: "#7a548d"
}

/* header styling start */
.navbar {
  top: 0px;
  margin: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.navbar a {
  color: white;
  text-decoration: none;
}

.navbar .logo {
  font-size: 2em;
  font-weight: bold;
}

.navbar .nav-links ul {
  display: flex;
}

.navbar .nav-links ul li {
  margin: 30px 25px;
}


.navbar .nav-links ul li.active a {
  color: var(--secondary-color);
  font-weight: bold;
}

.navbar .menu-hamburger {
  width: 25px;
  display: none;
}

.responsive-logo {
  display: none;
  font-size: 2em;
  font-weight: bold;
}

.button-download {
  background-color: #653aab !important;
  border-color: #7a50be !important;
  padding: 0px;
}









/* header styling end */


/* Tournment Boxes Styling Start */
.tournament-box {
  max-width: 600px;
  width: 90%;
  margin: 0 auto;
  height: 250px;
  border: 2px solid white;
  border-radius: 20px;
  position: relative;


  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  box-shadow: rgb(204, 219, 232) 3px 3px 6px 0px inset, rgba(255, 255, 255, 0.5) -3px -3px 6px 1px inset;
  background: linear-gradient(to bottom, var(--primary-color), var(--secondary-color));
  transition: background 0.9s ease, filter 0.9s ease;
  filter: brightness(0.8);
  cursor: pointer;
}

.tournament-box:hover {
  filter: none;
  background: linear-gradient(to bottom, var(--primary-color), var(--secondary-color));
}

.tournament-box h3,
.tournament-box h6 {
  color: white;
}

.top-area {
  height: 30px;
  width: 70%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  color: var(--secondary-color);

}



.center-area {
  height: 300px;
  width: 100%;

  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.center-area-box {
  height: 100%;
  width: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.center-area-img {
  height: 100px;
  width: 100px;
  /* border: 1px solid white; */
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}


.bottom-area {
  height: 30px;
  width: 70%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  color: var(--secondary-color);
}

/* Tournment Boxes Styling End */


/* Footer Styling Start */
.footer {
  min-height: 100;
  width: 100%;

  display: flex;
  justify-content: flex-end;
  align-items: center;

  position: relative;
  bottom: 10px;
}



/* Footer Styling End */


/* Swiper Styling Start */
.swiper-button-prev:after,
.swiper-rtl .swiper-button-next:after {
  font-size: 25px !important;
  font-weight: bold;
}

.swiper-button-next:after,
.swiper-rtl .swiper-button-prev:after {
  font-size: 25px !important;
  font-weight: bold;
}

.swiper-button-next,
.swiper-button-prev {
  color: #653aab !important;
  height: 50px !important;
  width: 50px !important;
  background-color: white;
  border-radius: 100%;
}

.swiper-button-disabled {
  color: #8b68c3 !important
}

/* Swiper Styling End */





/*  */

#myTab {
  border: none !important;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border: 1px solid var(--secondary-color) !important;
  width: 80%;
  border-radius: none !important;
  margin: auto;
  border-radius: 10px;
}

#myTab .active {
  background-color: var(--secondary-color) !important;
  border-radius: none !important;
  border-radius: 10px;
}

#myTab li {
  border: none !important;
  display: flex;
  border-radius: none !important;
  justify-content: space-evenly;
  align-items: center;
  border: none;
  width: 33.3%;
}

#myTab li button {
  width: 100%;
  border: none;
  color: white;
}

#myTab li:hover {
  border-radius: none !important;
}


#inprogress-tournment-cards {
  max-width: 600px;
}

@media (max-width: 768px) {
  #home-tabs {
    margin-top: 100px !important;
  }

  #myTab li button {
    font-size: 0.7em;
  }

  .top-area span {
    font-size: 12px;
  }

  .bottom-area span {
    font-size: 12px;
  }
}



/* match updates */
#match-updates-container {
  height: 100px;
  width: 80%;
  background-color: white;
  border-radius: 30px;

  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
}

#match-updates-container .tabs-number {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  background-color: #9a9a9b;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  cursor: pointer;
  font-weight: bold;
  transition: background 0.3s, color 0.3s;
}

#match-updates-container .tabs-number:hover {
  background: linear-gradient(to bottom, var(--primary-color), var(--secondary-color));
  color: white;

}

#match-updates-container .tabs-1,
#match-updates-container .tabs-2,
#match-updates-container .tabs-3,
#match-updates-container .tabs-4 {
  z-index: 999;
}

#match-updates-container .horizontal-line {
  width: 65%;
  border: 1px dotted black;
  position: absolute;
  top: 20%;
  z-index: 5;
}

#match-updates-container .active-tab {
  background: linear-gradient(to bottom, var(--primary-color), var(--secondary-color));
  color: white;
}







.custom-table--row {
  background-color: white;
  height: 100px;
  border-radius: 30px;
  margin-top: 10px;
}

@media (max-width: 768px) {
  #match-updates-container {
    margin-top: 50px;
  }

  #match-updates-container .tabs-number {
    height: 40px;
    width: 40px;
    border-radius: 100%;
    background-color: #9a9a9b;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 4;
    cursor: pointer;
    font-weight: bold;
    transition: background 0.3s, color 0.3s;
  }

  #match-updates-container h6 {
    font-size: 0.9em;
    text-align: center;
  }

  #match-updates-container .horizontal-line {
    top: 10%;
  }
}


/* Login Start */
.form-login {
  background: rgba(255, 255, 255, 0.2);
  /* backdrop-filter: blur(10px); */
  border-radius: 10px;
  border: 1px solid white;
  width: 50%;
  height: 500px;
  margin: auto;
  padding: 20px;
}

.form-login span {
  cursor: pointer;
}

.form-login span:hover {
  color: purple !important;
}

.input-fields {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}



.login-input {
  border: none;
  outline: none;
  height: 60px;
  width: 400px;
  border-radius: 40px;
  padding-left: 30px;
}

.login-input:focus {
  border: 1px solid purple;
}

.input-fields .btn-outline-primary:hover {
  background-color: #653aab;
  border-color: #653aab;
  color: white;
  font-weight: bold;
  font-size: 15px;
}

.input-fields .btn-outline-primary {
  background-color: white;
  width: 400px;
  height: 40px;
  border-radius: 40px;
  color: #653aab;
  border-color: #653aab;
  font-weight: bold;
  font-size: 15px;
}


@media (max-width: 768px) {
  .form-login {
    width: 90%;
    height: auto;
    padding: 10px;
    margin-top: 100px;
  }

  .login-input {
    width: 90%;
    height: 40px;
  }

  .input-fields .btn-outline-primary {
    width: 90%;
    /* Adjust button width */
    height: 35px;
    /* Adjust button height */
  }

  #profile-page-style {
    margin-top: 100px !important;
  }

 

  .text-profile {
    font-size: 0.9em !important;
  }

  .profile-btn-area button {
    font-size: 0.6em !important;
    padding: 10px 10px !important;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .modal-content {
    width: 100% !important;
  }
}

/* Login End */






/* modal styling start*/
.modal-content {
  height: 400px;
  width: 500px;
  border: 3px solid white;
  background: #946bd4;
  border-radius: 15px;
}

.modal-content .modal-cross-icon {
  height: 45px;
  width: 45px;
  background: white;
  border-radius: 100%;
  position: absolute;
  right: 20px;
  top: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.modal-body .tabs {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-body .tabs button:first-child {
  width: 100%;
  border: none;
  outline: none;
  background: none;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 5px 30px;
}

.modal-body .tabs button:last-child {
  width: 100%;
  border: none;
  outline: none;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 5px 30px;
  background: none;
}

/* modal styling end */



/* Card Design */

.card {
  border-radius: 15px;
  background: linear-gradient(145deg, #8e6ccb, #7853bb);
  color: white;
  padding: 20px;
  margin: auto;
  font-family: Arial, sans-serif;
}

.card-top-header {
  height: 50px;
  width: 90%;
  background-color: white;
  position: absolute;
  top: 0;
  /* Center vertically */
  left: 50%;
  /* Center horizontally */
  transform: translate(-50%, 0%);
  /* Adjust for the element's dimensions */
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px;
}

.card-top-header h6 {
  color: #6f5298;
  font-weight: 600;
}

.card-top-footer {
  height: 50px;
  width: 100%;
  background-color: white;
  position: absolute;
  bottom: 0;
  /* Center vertically */
  left: 50%;
  /* Center horizontally */
  transform: translate(-50%, 0%);
  /* Adjust for the element's dimensions */
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.title,
.entry {
  text-align: left;
  margin: 5px 0;
}

.prize,
.spots,
.entry {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.prize-amount,
.spots-left,
.entry-fee {
  font-weight: bold;
}

.total-spots {
  font-size: 0.9em;
}

.trophy {
  background: #81459b;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trophy::before {
  content: "\f091";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  font-size: 1.5em;
}

.progress-bar {
  background: white;
  border-radius: 10px;
  height: 20px;
  width: 100%;
  margin: 10px 0;
  position: relative;
  border: 1px solid white;
}

.progress {
  background: #81459b;
  height: 100%;
  border-radius: 10px;
  width: 70%;
  /* Adjust width according to progress */
}

/* Card Design */




#select-team-container {
  height: 100px;
  width: 100%;
  background: #9d76e1;
  border-radius: 30px;
  border: 1px solid white;
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: relative;
}

#select-team-container .tabs-number {
  height: 50px;
  width: 50px;
  border-radius: 100%;
  background-color: #9a9a9b;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 4;
  cursor: pointer;
  font-weight: bold;
  transition: background 0.3s, color 0.3s;
}

#select-team-container .tabs-number:hover {
  background: linear-gradient(to bottom, var(--primary-color), var(--secondary-color));
  color: white;

}

#select-team-container .tabs-1,
#select-team-container .tabs-2,
#select-team-container .tabs-3,
#select-team-container .tabs-4 {
  z-index: 999;
}

#select-team-container .horizontal-line {
  width: 65%;
  border: 1px dotted black;
  position: absolute;
  top: 20%;
  z-index: 5;
}

#select-team-container .active-tab {
  background: linear-gradient(to bottom, var(--primary-color), var(--secondary-color));
  color: white;
}



.select-team-caption-voice-caption .btn-primary {
  border: 1px solid #653aab;
  background-color: #653aab;
  color: white;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-team-caption-voice-caption .btn-outline-primary {
  border: 1px solid #653aab;
  color: #653aab;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-team-caption-voice-caption .btn-outline-primary:hover {
  border: 1px solid #653aab;
  background-color: #653aab;
  color: white;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}


.pin-input {
  width: 70px;
  height: 70px;
  text-align: center;
  margin: 0 5px;
  font-size: 18px !important;
}


#verification-container .form-login {
  height: 400px;
}

#verification-container button {
  width: 400px;
  height: 60px;
  background-color: #431c83;
  color: white;
}

#operator-signup {
  width: 23%;
  position: absolute;
  right: 30%;
}


@media screen and (max-width:900px) {
  .footer {
    /* padding: 40px; */
  }

  .footer .tagline {
    margin-top: 20px;
  }

  #operator-signup {
    width: 30%;
    right: 10%;
  }
}


@media screen and (max-width:900px) {
  body {
    height: 1000px !important;
    background: #653aab !important;
  }

  .navbar {
    margin: 0;
    z-index: 9999999;
  }

  .navbar .logo {
    display: none;
  }

  .navbar .responsive-logo {
    display: block;
    position: absolute;
    top: 40px;
    left: 50px;
    z-index: 9999;
  }


  .navbar .menu-hamburger {
    display: block;
    position: absolute;
    right: 50px;
    top: 50px;
    z-index: 9999;
  }

  .nav-links {
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(to bottom, var(--primary-color), var(--secondary-color));
    width: 100vw;
    height: 100vh;
    z-index: 99;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: -100%;
    transition: all 0.7s ease;
  }

  .nav-links::after {
    content: "";
    height: 50%;
    width: 5px;
    background-color: var(--secondary-color);
    position: absolute;
    right: 0px;
    top: 20%;
    /* border-bottom-left-radius: 100%;
    border-top-left-radius: 100%; */
  }

  .nav-links.mobile-menu {
    margin-left: 0;
  }

  .nav-links ul {
    flex-direction: column;
  }

  .navbar .nav-links ul li {
    margin: 25px 0;
    font-size: 1.2em;
  }

  .tournament-box h6,
  .tournament-box span {
    font-size: 0.7em;
  }

  .top-area .text {
    font-size: 0.6em
  }

  #match-updates-container .tab-text-match-updates {
    font-size: 0.7em;
  }

  #match-updates-container .horizontal-line {
    position: relative;
    top: 22% !important;
    display: none;
  }

  .length-text {
    font-size: 0.8em;
    margin-top: 10px;
  }

  #enter-contest-tabs button {
    font-size: 0.6em;
    padding: 1px 20px;
  }

  #contest-responsive-card {
    overflow: hidden;
  }


  #contest-responsive-card h6,
  #contest-responsive-card h3,
  #contest-responsive-card h5 {
    font-size: 0.8em;
    margin-right: 20px !important;
    margin-left: 0px !important;
    padding-left: 0px !important;
    white-space: nowrap;
  }

  #card-contest-footer {
    min-width: 100% !important;
    justify-content: flex-start !important
  }

  #all-contest-footer {
    width: 100% !important;
    justify-content: flex-start !important;
    margin: 0px !important;
    padding: 0px !important;
  }


  #create-team-btn {
    padding: 10px 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .modal-content {
    width: 100% !important;
  }

  #my-contest-items {
    width: 270px !important;
    margin: 0px !important;
    padding: 0px !important;
    height: 40px !important;
    margin-left: 0px;
    position: relative;
    right: 60px !important;
  }

  #my-contest-items h3 {
    font-size: 0.9em !important;
    margin: 0px !important;
    padding: 0px !important;
  }

  #my-contest-items .special-item {
    font-size: 0.9em !important;
    padding: 5px !important;
  }

  #my-contest-area {
    margin-top: 100px;
  }

  .table-bordered {
    margin-left: 10px !important;
  }

  #select-teams-table {
    overflow: hidden;
    min-width: 100% !important;
  }

  #select-teams-table table {
    overflow: hidden;
    width: 100% !important;
  }

  #select-teams-table table tr {
    font-size: 0.7em !important;
  }

  #profile-page-style{
    overflow: hidden !important;
  }

  #deposite-model {
    width: 100vw !important;
    overflow: hidden;
  }

  #deposite-model .modal-content {
    min-width: 100% !important;
  }
  #deposite-model .modal-body .tabs button ,#deposite-model .modal-body .tabs .active{
    font-size: 0.7em !important;
  }
  #withdraw-model .modal-content {
    width: 100% !important;
  }
  
  .table-transaction td{
    font-size: 10px;
  }
  #see-rules-area{
   position: absolute !important;
    bottom: 100%;
    right: 0px !important;
    background-color: #431c83;
    color: white;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    border-top-left-radius: 10px !important;
  }
  #see-rules-area h5{
    color: white !important;
    margin-left: 10px !important;
    margin-top: 10px !important;
    margin-right: 10px !important;
  }

  #total-spots-area{
    margin-bottom: 70px !important;
  }
}